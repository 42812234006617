import React from "react"

const QuantityButton = (props) => {

  const handleClick = (event, option) => {
    event.preventDefault();
    props.handleAmountToggle(option)
  };

  return (

      <div className="quantity-toggle-container is-inline">
        <button  className="is-inline quantity-toggle-button" onClick={(e) => handleClick(e, "-")}>
          -
        </button>
        <p className="is-inline" >{props.quantity ? props.quantity : 0}</p>
        <button  className="is-inline quantity-toggle-button" onClick={(e) => handleClick(e, "+")}>
          +
        </button>
      </div>

  )

}

export default QuantityButton
