import React from "react";
import _get from "lodash.get";
import { renderTags, renderProductNamewithDosage } from "../../../utilities/product";
import { formatAmount } from "../../../lib/helpers";

export default function OrderRow ({ orderItem, product }) {

  const imageSrc = _get(product, "images[0].src");
  const imageAlt = _get(product, "images[0].alt") || product.name || null;

  return (
    <div className="columns product-row order-row is-vcentered is-variable is-mobile is-multiline">
      <div className="column is-narrow">
        <figure className="image product-row-image is-square is-100x100">
          {imageSrc && <img src={imageSrc} alt={imageAlt} />}
        </figure>
      </div>
      <div className="column">
        <p className="is-size-4 is-inline is-underlined">{renderProductNamewithDosage(product)}</p>
        <p>{renderTags(product)}</p>
      </div>
      <div className="column is-12-mobile is-5-tablet">
        <div className="columns is-vcentered is-mobile">
          <div className="column is-6">
            <p>Amount: {orderItem.quantity}</p>
          </div>
          <div className="column is-6">
            <p className="is-size-6 has-text-right">${formatAmount(parseFloat(orderItem.price))}</p>
          </div>
        </div>
      </div>
    </div>
  );

}
