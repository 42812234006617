import React, { Component } from "react";
import SubscriptionTitle from "./subscription-title";
import SubscriptionRow from "./subscription-row";
import SubscriptionFooter from "./subscription-footer";
import PropTypes from "prop-types";
import { formatAmount } from "../../../lib/helpers";
import api from "../../../api";

class Subscription extends Component{
  constructor (props) {
    super(props);
    this.state = {
      quantities: props.subscription.line_items.map(item => {
        return {id:item.id,quantity:item.quantity};
      }),
      total: parseFloat(props.subscription.total),
      fee: 0,
      confirming: false
    };
    this.handleAmountToggle = this.handleAmountToggle.bind(this);
    this.handleConfirmChange = this.handleConfirmChange.bind(this);
  }
  handleAmountToggle(sign, id){
    var index = this.state.quantities.findIndex(q=>q.id===id);
    let newAmount = sign === "+" ? this.state.quantities[index].quantity + 1 : this.state.quantities[index].quantity - 1;
    if (newAmount < 0) newAmount = 0;
    this.setState({
      quantities: [
        ...this.state.quantities.slice(0,index),
        {id:id, quantity:newAmount},
        ...this.state.quantities.slice(index+1)
      ]
    });
    const subItem = this.props.subscription.line_items.find( i => i.id === id);
    let changeValue = (newAmount-subItem.quantity) * (parseFloat(subItem.price) + parseFloat(subItem.total_tax)/subItem.quantity);
    let total = 0;
    if(this.state.quantities.length > 1){
        this.state.quantities.forEach(item => {
          const findItem = this.props.subscription.line_items.find( i => i.id === item.id);
          total = total + parseFloat(findItem.price)*item.quantity;
          if(parseFloat(findItem.total_tax) > 0){
            total = total + parseFloat(findItem.price)*item.quantity*0.0725;
          }
      })
      total = total + parseFloat(this.props.subscription.shipping_total);
      changeValue = sign === "+" ? (parseFloat(subItem.price) + parseFloat(subItem.total_tax)/subItem.quantity) : 0 - (parseFloat(subItem.price) + parseFloat(subItem.total_tax)/subItem.quantity)
    }
    if(this.props.subscription.fee_lines.length > 0 && "Route Shipping Protection" === this.props.subscription.fee_lines[0].name){
      let totalWithoutFee = parseFloat(this.props.subscription.total) - parseFloat(this.props.subscription.fee_lines[0].total);
      if(this.state.quantities.length > 1){
        totalWithoutFee = total;
      }
      window.routeapp.get_quote("5de71692-9880-4993-9401-54c28a0a6c3e", (totalWithoutFee + changeValue), "USD", insurance_details => {
        // TODO: If insurance_details.insurance_selected response is true, add the Route insurance to order total
        this.setState({total: totalWithoutFee + changeValue + insurance_details.insurance_price});
        if(insurance_details.insurance_price !== parseFloat(this.props.subscription.fee_lines[0].total)){
          this.setState({fee: insurance_details.insurance_price});
        }
      });
    }else{
      if(this.state.quantities.length > 1){
        this.setState({total: total + changeValue});
      }else{
        this.setState({total: parseFloat(this.props.subscription.total) + changeValue});
      }
    }
  };
  async handleConfirmChange (){
    this.setState({confirming: true});
    const data = {
      line_items: this.state.quantities.map( item => {
          const subItem = this.props.subscription.line_items.find( i => i.id === item.id);
          return {id: item.id, product_id: subItem.product_id, quantity: item.quantity, total: (parseFloat(subItem.price)*item.quantity).toFixed(2)}
      })
    };
    if(this.state.fee > 0 && this.props.subscription.fee_lines.length > 0){
      data.fee_lines = [{id: this.props.subscription.fee_lines[0].id, name: this.props.subscription.fee_lines[0].name, total: this.state.fee.toString()}]
    }
    const responseSubscription = await api.subscriptions.updateSubscription(this.props.subscription.id, data);
    this.setState({confirming: false});
    this.props.handleUpdateSubscription(responseSubscription);
  }
  render () {
    const {  subscription,products,onEditClick, onCancelClick } = this.props;
    const findProduct = product_id => products.find(p => p.wordpress_id === product_id);
    const subscriptionItems = subscription.line_items.map(item => {
      const product = findProduct(item.product_id);
      return Object.assign({ product }, item);
    });
    return (
      <div className="subscription">
        <SubscriptionTitle
          subscription={subscription}
          onEditClick={onEditClick}
          onCancelClick={onCancelClick}
        />
        {subscriptionItems.map(subscriptionItem =>
          <SubscriptionRow
            key={subscriptionItem.id}
            item={subscriptionItem}
            product={subscriptionItem.product}
            handleAmountToggle={this.handleAmountToggle} 
            quantities={this.state.quantities}
            handleConfirmChange={this.handleConfirmChange}
            confirming={this.state.confirming}/>)}
        <SubscriptionFooter subscription={subscription} products={products} total={this.state.total}/>
      </div>
    );
  }
}
Subscription.propTypes = {
  subscription: PropTypes.object.isRequired,
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  onEditClick: PropTypes.func,
  onCancelClick: PropTypes.func
};
export default Subscription