import React from "react";
import OrderRow from "./order-row";
import OrderGroupFooter from "./order-group-footer";

export default function OrderGroup (
  {
    title = "Order group",
    type = "simple",
    showFooter = true,
    orderItems = []
  }
) {
  return (
    <div className="order-group">
      <h4 className="order-group-title">{title}</h4>
      {orderItems.map(orderItem =>
        <OrderRow key={orderItem.id} orderItem={orderItem} product={orderItem.product} />)}
      {showFooter && <OrderGroupFooter type={type} orderItems={orderItems} />}
    </div>
  );
}
