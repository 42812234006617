import React from "react";
import parseISO from "date-fns/parseISO";
import format from "date-fns/format";
import { formatAmount } from "../../../lib/helpers";

export default function OrderTitle ({ order }) {
  return (
    <div className="order-title columns is-mobile is-vcentered">
      <div className="column is-narrow">
        <h4 className="has-text-primary">Order #{order.number}</h4>
      </div>
      <div className="column is-narrow">
        <p>{format(parseISO(order["date_created"]), "MM/dd/yyyy")}</p>
      </div>
      <div className="column is-narrow">
        <p className="has-text-weight-bold">Total paid: ${formatAmount(parseFloat(order.total))}</p>
      </div>
    </div>
  );
}
