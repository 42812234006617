import React from "react";
import Highlightable from "../../highlightable";
import Accordion from "../../accordion";
import OrderTitle from "./order-title";
import OrderContent from "./order-content";

export default function orderHistory ({ products = [], orders = [] }) {
  const accordionData = orders.map(order => {
    return {
      title: <OrderTitle order={order} />,
      content: <OrderContent order={order} products={products} />
    };
  });

  return (
    <section className="section">
      <div className="container">
        <h3 className="has-text-weight-bold"><Highlightable>Order History</Highlightable></h3>
        {orders.length > 0 ?
          <Accordion data={accordionData} />
          :
          <p>Your order history is empty</p>
        }
      </div>
    </section>
  );
}
