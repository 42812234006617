import React, { Component } from "react";
import { loadSquareJs } from "../../lib/helpers";
import api from "../../api";
import _get from "lodash.get";

export default class UpdateBillingInfo extends Component {

  state = {
    saving: false,
    squareErrors: null
  };

  componentDidMount () {
    this.createSqPaymentForm().then(() => {
      console.log("SqPaymentForm created");
    });
  }

  createSqPaymentForm = async () => {
    if (!window.SqPaymentForm) await loadSquareJs();

    // Create and initialize a payment form object
    const paymentForm = new window.SqPaymentForm({
      // Initialize the payment form elements
      applicationId: process.env.GATSBY_SQUARE_APPLICATION_ID,
      inputClass: "input",
      autoBuild: false,
      // Customize the CSS for SqPaymentForm iframe elements
      inputStyles: [
        {
          fontSize: "16px",
          lineHeight: "24px",
          placeholderColor: "#9e9c9a",
          backgroundColor: "transparent"
        }
      ],
      // Initialize the credit card placeholders
      cardNumber: {
        elementId: "upd-sq-card-number",
        placeholder: "Card Number"
      },
      cvv: {
        elementId: "upd-sq-cvv",
        placeholder: "CVV"
      },
      expirationDate: {
        elementId: "upd-sq-expiration-date",
        placeholder: "MM/YY"
      },
      postalCode: {
        elementId: "upd-sq-postal-code",
        placeholder: "Postal"
      },
      // SqPaymentForm callback functions
      callbacks: {
        /*
         * callback function: cardNonceResponseReceived
         * Triggered when: SqPaymentForm completes a card nonce request
         */
        cardNonceResponseReceived: async (errors, nonce, cardData) => {
          this.setState({ squareErrors: errors });
          if (errors) return this.setState({ saving: false });

          try {
            this.setState({ saving: true });
            console.log(`The generated nonce is:`, { nonce });
            const card = await api.account.saveCard({ card_nonce: nonce });
            this.setState({ saving: false });
            this.props.onSuccess && this.props.onSuccess(card);
          } catch (e) {
            this.setState({ saving: false });
            console.error(e);
            alert("Payment failed to complete!\nCheck browser developer console for more details");
          }
        }
      }
    });
    paymentForm.build();
    this.paymentForm = paymentForm;
  };

  onGetCardNonce = e => {
    e.preventDefault();
    this.setState({ saving: true });
    this.paymentForm.requestCardNonce();
  };

  render () {

    // square errors
    const getSquareError = field => {
      const error = (this.state.squareErrors || []).find(e => e.field === field);
      return _get(error, "message", null);
    };
    const cardNumberError = getSquareError("cardNumber");
    const cvvError = getSquareError("cvv");
    const expirationDateError = getSquareError("expirationDate");
    const postalCodeError = getSquareError("postalCode");

    return (
      <div className="container spacing">

        <form onSubmit={this.onGetCardNonce} className="form">

          <div className="field">
            <div id="upd-sq-card-number" />
            {cardNumberError && <p className="help is-danger">{cardNumberError}</p>}
          </div>

          <div className="field is-horizontal">
            <div className="field-body">
              <div className="field">
                <div id="upd-sq-expiration-date" />
                {expirationDateError && <p className="help is-danger">{expirationDateError}</p>}
              </div>
              <div className="field">
                <div id="upd-sq-cvv" />
                {cvvError && <p className="help is-danger">{cvvError}</p>}
              </div>
              <div className="field">
                <div id="upd-sq-postal-code" />
                {postalCodeError && <p className="help is-danger">{postalCodeError}</p>}
              </div>
            </div>

          </div>

          <br />
          <button id="sq-creditcard" className={`button is-primary is-fullwidth${this.state.saving ? " is-loading" : ""}`} type="submit">
            UPDATE
          </button>

        </form>

      </div>
    );
  }

}
