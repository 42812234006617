import React from "react";

export default function accountShippingInfo (
  {
    shippingInfo,
    onEditClick = () => {
      console.log("on edit click");
    }
  }
) {
  return (
    <div className="box has-yellow-background has-text-white" style={{ height: "100%" }}>
      <h4 className="has-text-weight-bold">Shipping Address</h4>
      <br />
      {shippingInfo.first_name}&nbsp;{shippingInfo.last_name}<br />
      {shippingInfo.address_1}<br />
      {shippingInfo.postcode}&nbsp;{shippingInfo.city}&nbsp;{shippingInfo.state}<br />
      {shippingInfo.country}<br />
      <br />
      <button type="button" className="button select-button has-text-danger" onClick={onEditClick}>EDIT</button>
    </div>
  );
}
