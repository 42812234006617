import React, { Component, createRef, Fragment } from "react";
import { graphql, navigate } from "gatsby";
import update from "immutability-helper";
import api from "../api";
import auth from "../lib/auth";
import Rejoiner from "../lib/tracking/rejoiner";
import SEO from "../components/seo";
import AccountLoading from "../components/account/account-loading";
import EditAccount from "../components/account/edit-account";
import AccountHero from "../components/account/account-hero";
import Subscriptions from "../components/account/subscriptions/subscriptions";
import OrderHistory from "../components/account/orders/order-history";
import AccountShippingInfo from "../components/account/account-shipping-info";
import AccountBillingInfo from "../components/account/account-billing-info";
import UpdateBillingInfo from "../components/account/update-billing-info";
import UpdateShippingInfo from "../components/account/update-shipping-info";
import AccountInfo from "../components/account/account-info";
import InstaFeed from "../components/instaFeed";
import { loadRouteJs } from "../lib/helpers";
import Modal from "../components/modal";
import ConfirmModal from "../components/modal/confirm-modal";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./account.scss";
import Header from "../components/header";
import Footer from "../components/footer";
import GlobalHelmet from "../components/global-helmet";
import GlobalModals from "../components/global-modals";
import axios from "axios";

class AccountPage extends Component {
  constructor (props) {
    super(props);
    this.state = {
      pageLoading: true,
      customer: {},
      orders: [],
      subscriptions: []
    };
    this._confirmModal = createRef();
    this._header = createRef();
  }

  componentDidMount () {
    if (!auth.loggedIn) return navigate("/");
    this.loadAccountPageData().then(() => {
      console.log("account page data loaded");
    });
    this.getRouteQuote().then(() => {
      console.log("getRouteQuote created");
    });
  }
  getRouteQuote = async () => {
    if (!window.routeapp) await loadRouteJs();
  }

  loadAccountPageData = async () => {
    try {
      this.setState({ pageLoading: true });
      const [customer, orders, subscriptions] = await Promise.all([
        api.account.getMyAccount(),
        api.orders.getOrders(),
        api.subscriptions.getSubscriptions()
      ]);
      this.setState({ customer, orders, subscriptions });
    } 
    catch(err) {
      if(err.message.indexOf("status code 401") !== -1) {
        this._header.current.openLoginModal(false, () => {
          window.location.reload();
        });
      }
    }  
    finally {
      this.setState({ pageLoading: false });
    }
  };

  handleEditSubscription = () => {
    // todo implement edit subscription
    console.error("handleEditSubscription: unimplemented");
  };
  handleUpdateSubscription = subscription => {

    var index = this.state.subscriptions.findIndex(p=>p.id===subscription.id);
    this.setState({
      subscriptions: [
        ...this.state.subscriptions.slice(0,index),
        subscription,
        ...this.state.subscriptions.slice(index+1)
      ]
    });
    toast.success("Your subscription has been successfully updated", {
      hideProgressBar: true,
      position: "bottom-right",
      autoClose: 3500
    });
  }

  handleCancelSubscription = subscription => {
    if (!subscription) return;

    this._confirmModal.current.ask({
      title: "Confirm action",
      text: "Are you sure you want cancel subscription?",
      callback: async () => { // confirmed

        const index = this.state.subscriptions.findIndex(s => s.id === subscription.id);

        if (~index) {

          // set canceling:true
          this.setState({ subscriptions: update(this.state.subscriptions, { [index]: { canceling: { $set: true } } }) });

          // delete subscription serverside
          await api.subscriptions.deleteSubscription(subscription.id);

          // set canceling:false
          this.setState({ subscriptions: update(this.state.subscriptions, { [index]: { canceling: { $set: false } } }) }, () => {

            // delete subscription clientside
            this.setState({ subscriptions: update(this.state.subscriptions, { $splice: [[index, 1]] }) });

            const findSubscription = this.state.subscriptions.filter(s => s.id !== subscription.id).find(s => s.status === "active");

            if(typeof findSubscription === "undefined"){
              /*
              Rejoiner.subscribeToList({
                email: subscription.billing.email,
                first_name: subscription.billing.first_name,
                list_id: process.env.GATSBY_REJOINER_UNSUBSCRIBE_SUBSCRIBER_LIST_ID
              });
              */
              const api_url = `https://smile-api-production.herokuapp.com/api/v1/smile-cancel`
              const formData = { api_key: "pk_a67d8ed7894f8e1e56ada9eb616df05f7a",profiles: [{email: subscription.billing.email}] }
              axios.post(api_url, formData)
            }
            // success toast message
            toast.success("Your subscription has been successfully canceled", {
              hideProgressBar: true,
              position: "bottom-right",
              autoClose: 3500
            });
          });
        }
      }
    });
  };

  handleShippingInfoModalOpen = () => {
    this.setState({ editingType: "shipping", isModalOpen: true });
  };

  handleBillingInfoModalOpen = () => {
    this.setState({ editingType: "billing", isModalOpen: true });
  };

  handleAccountInfoModalOpen = () => {
    this.setState({ editingType: "account", isModalOpen: true });
  };

  handleModalClose = () => {
    if (this.state.isModalOpen) {
      this.setState({ isModalOpen: false });
    }
  };

  handleSuccessUpdateShipping = newShipping => {
    this.handleModalClose();
    if (newShipping) {
      const customer = update(this.state.customer, { shipping: { $set: newShipping } });
      this.setState({ customer });
    }
  };

  handleSuccessUpdateBilling = card => {
    this.handleModalClose();
    const customer = update(this.state.customer, { card: { $set: card } });
    this.setState({ customer });
  };

  handleEditShippingInfo = () => {};

  handleEditAccountInfo = () => {};

  render () {

    const products = this.props.data.allWcProducts.edges.map(e => e.node);

    return (
      <>
      <Header ref={this._header}/>
      <div id="main-wrapper">
        <main id="main">
        <SEO title="My Account" />

        {this.state.pageLoading ?
          <AccountLoading />
          :
          <Fragment>
            <AccountHero customer={this.state.customer} />
            {this.state.subscriptions.length > 0 &&
            <Subscriptions
              subscriptions={this.state.subscriptions}
              products={products}
              handleEditSubscription={this.handleEditSubscription}
              handleCancelSubscription={this.handleCancelSubscription} 
              handleUpdateSubscription={this.handleUpdateSubscription} />
            }
            <OrderHistory orders={this.state.orders} products={products} />
            {
              this.state.customer &&
              <section className="section">
                <div className="container">
                  <div className="columns">
                    <div className="column">
                      {this.state.customer.shipping &&
                      <AccountShippingInfo shippingInfo={this.state.customer.shipping} onEditClick={this.handleShippingInfoModalOpen} />
                      }
                    </div>
                    <div className="column">
                      {this.state.customer.card &&
                      <AccountBillingInfo card={this.state.customer.card} onEditClick={this.handleBillingInfoModalOpen} />
                      }
                    </div>
                    <div className="column">
                      <AccountInfo customer={this.state.customer} onEditClick={this.handleAccountInfoModalOpen} />
                    </div>
                  </div>
                </div>

              </section>
            }
          </Fragment>
        }

        <section className="section is-medium">
          <InstaFeed />
        </section>


        <Modal isModalOpen={this.state.isModalOpen} closeModal={this.handleModalClose}>
          {(() => {
            switch (this.state.editingType) {
              case "shipping":
                return <Fragment>
                  <h2>Shipping Info</h2>
                  <p>Let us know your new shipping address! #reasontosmile</p>
                  <br />
                  <UpdateShippingInfo
                    shipping={this.state.customer.shipping}
                    onSuccess={this.handleSuccessUpdateShipping} />
                </Fragment>;


              case "billing":
                return <Fragment>
                  <h2>Update Billing Info</h2>
                  <p>Please enter your new payment method</p>
                  <br />
                  <UpdateBillingInfo onSuccess={this.handleSuccessUpdateBilling} />
                </Fragment>;

              case "account":
                return <EditAccount user={this.state.customer} />;

              default:
                return null;
            }
          })()}
        </Modal>

        <ConfirmModal ref={this._confirmModal} />
        <ToastContainer />

        </main>
      </div>
      <GlobalModals />
      <Footer />
      <GlobalHelmet />
    </>
    );
  }
}

export default AccountPage;


// Set here the ID of the home page.
export const pageQuery = graphql`
    query {
        allWcProducts(filter: {status: {eq: "publish"}}) {
            edges {
                node {
                    id
                    wordpress_id
                    sku
                    slug
                    name
                    type
                    price
                    categories {
                        wordpress_id
                        name
                    }
                    acf {
                      flavor
                      effect
                      product_type
                    }
                    grouped_products
                    images {
                        id
                        alt
                        src
                    }
                }
            }
        }
    }
`;
