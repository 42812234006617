import React from "react";
import { formatAmount } from "../../../lib/helpers";

export default function OrderGroupFooter ({ orderItems = [], type = "simple" }) {

  const total = orderItems.reduce((sum, orderItem) => sum + orderItem.price * orderItem.quantity, 0);

  return (
    <footer className="order-group-footer">
      <div className="columns">
        <div className="column is-offset-7-tablet is-5-tablet">
          <div className="columns is-mobile">
            <div className="column">
              <p className="has-text-weight-bold">Cost per bill</p>
            </div>
            <div className="column">
              <p className="has-text-weight-bold has-text-right">
                {type === "subscription" ? `$${formatAmount(total)} / month` : `$${formatAmount(total)}`}
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
