import React from "react";
import { formatAmount } from "../../../lib/helpers";


export default function SubscriptionFooter ({ subscription, products = [], total }) {

  const findSimpleProduct = (subs_product_id) => {
    const parentProduct = products.find(p => p.type === "grouped" && p.grouped_products.includes(subs_product_id));
    if (parentProduct) {
      const simpleProductId = parentProduct.grouped_products.find(id => id !== subs_product_id);
      if (simpleProductId) {
        return products.find(p => p.wordpress_id === simpleProductId);
      }
    }
    return null;
  };

  const totalWithoutDiscount = subscription.line_items.reduce((sum, item) => {
    let simpleProductSum = 0;

    const simpleProduct = findSimpleProduct(item.product_id);
    if (simpleProduct) {
      simpleProductSum = parseFloat(simpleProduct.price) * item.quantity;
    }

    return sum + simpleProductSum;
  }, 0);

  const subscriptionTotal = parseFloat(subscription.total);
  const saveSum = totalWithoutDiscount - subscriptionTotal;

  return (
    <footer className="subscription-footer">
      <div className="columns">
        <div className="column is-offset-7-tablet is-5-tablet">
          <div className="columns is-mobile">
            <div className="column">
              <p>Subscription savings</p>
              <p className="has-text-weight-bold">Cost per bill</p>
            </div>
            <div className="column has-text-right">
              <p>${formatAmount(saveSum)} / month</p>
              { total === parseFloat(subscription.total) ?
              <p className="has-text-weight-bold">${formatAmount(subscriptionTotal)} / month</p>
              :
              <p className="has-text-weight-bold">${formatAmount(total)} / month</p>
              }   
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
