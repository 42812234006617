import React, { Component } from "react";
import anime from "animejs/lib/anime.es.js";
import { isBrowser } from "react-device-detect";
import axios from "axios";
import _get from "lodash.get";
const INSTAGRAM_TOKEN = process.env.GATSBY_INSTAGRAM_TOKEN;

class InstaFeed extends Component {

  constructor (props) {
    super(props);
    this.state = {
      titleHighlighted: false,
      items: [
        // here empty images for constant document height, cause it broke fullpage.js calc
        // otherwise need call reBuild method of fullpage.js api
        // see https://github.com/alvarotrigo/fullPage.js/#rebuild
        { url: null },
        { url: null },
        { url: null },
        { url: null },
        { url: null}
      ]
    };
    this._followLink = React.createRef();
  }

  handleEnterViewport = () => {
    anime.remove(".instafeed-message");
    anime({
      targets: ".instafeed-message",
      width: "100%",
      easing: "easeInOutQuad",
      duration: 1000
    });
    this.setState({ titleHighlighted: true });
  };

  handleLeaveViewport = () => {
    anime.remove(".instafeed-message");
    anime({
      targets: ".instafeed-message",
      width: "0%",
      easing: "easeInOutQuad",
      duration: 1000
    });
    this.setState({ titleHighlighted: false });
  };

  componentDidMount () {
    //const username = "smile";
    axios.get(`https://graph.instagram.com/me/media?fields=id,caption,media_url,permalink,media_type,thumbnail_url&access_token=${INSTAGRAM_TOKEN}`).then(response => {
      const items = response.data.data.slice(0, 5)
        .map(item => {
          return {
            url: item.permalink,
            src: (item.media_type === "IMAGE" || item.media_type === 'CAROUSEL_ALBUM')? item.media_url:item.thumbnail_url,
            text: item.caption
          };
        });
      this.setState({ items });
    });

    // intersection observer
    const inObserver = new IntersectionObserver((entries) => {
      const entry = entries[0];
      if (entry) {
        if (entry.isIntersecting && entry.intersectionRect.top) {
          this.handleEnterViewport();
        } else if (entry.boundingClientRect.top > 0) {
          this.handleLeaveViewport();
        }
      }
    });
    // noinspection JSCheckFunctionSignatures
    inObserver.observe(this._followLink.current);
    this.inObserver = inObserver;
  }

  componentWillUnmount () {
    this.inObserver && this.inObserver.disconnect();
  }

  render () {
    return (
      <div className="container has-text-centered">

      
        <h4 className="has-text-weight-bold">
          <span className="highlightable"><span>Wait, there's more</span></span>
        </h4>
        <h1>Let's stay in touch</h1>
        <br />
        <div className="insta-message-1">
          <a target="_blank" href="https://instagram.com/smile" rel="noopener noreferrer">
            <img data-src={require("../images/instafeed/message-01.svg")} className="instafeed-message lazyload" style={{ zIndex: "100" }} alt="" />
          </a>
        </div>
        <div className="columns is-mobile is-multiline instafeed-columns">
              <div className="column is-6-mobile is-3-desktop is-3-tablet instafeed-1">
                <figure className="image is-1by1 instafeed-image">
                  {this.state.items[0].url &&
                  <a href={this.state.items[0].url} target="_blank" rel="noopener noreferrer"><img data-src={this.state.items[0].src} className="lazyload" alt={this.state.items[0].text} /></a>}
                </figure>
              </div>
              <div className="column is-6-mobile is-3-desktop is-3-tablet instafeed-2">
                <figure className="image is-1by1 instafeed-image first-image">
                  {this.state.items[1].url &&
                  <a href={this.state.items[1].url} target="_blank" rel="noopener noreferrer"><img data-src={this.state.items[1].src} className="lazyload" alt={this.state.items[1].text} /></a>}
                </figure>
                <figure className="image is-1by1 instafeed-image second-image">
                  {this.state.items[2].url &&
                  <a href={this.state.items[2].url} target="_blank" rel="noopener noreferrer"><img data-src={this.state.items[2].src} className="lazyload" alt={this.state.items[2].text} /></a>}
                </figure>
              </div>
              <div className="column is-6-mobile is-3-desktop is-3-tablet instafeed-3">
                <figure className="image is-1by1 instafeed-image">
                  {this.state.items[3].url &&
                  <a href={this.state.items[3].url} target="_blank" rel="noopener noreferrer"><img data-src={this.state.items[3].src} className="lazyload" alt={this.state.items[3].text} /></a>}
                </figure>
              </div>
              <div className="column is-6-mobile is-3-desktop is-3-tablet instafeed-4">
                <figure className="image is-1by1 instafeed-image">
                  {this.state.items[4].url &&
                  <a href={this.state.items[4].url} target="_blank" rel="noopener noreferrer"><img data-src={this.state.items[4].src} className="lazyload" alt={this.state.items[4].text} /></a>}
                </figure>
              </div>
             
           
        </div>
        <div className="insta-message-2">
          <a target="_blank" href="https://instagram.com/smile" rel="noopener noreferrer">
            <img data-src={require("../images/instafeed/message-02.svg")} className="instafeed-message lazyload" style={{ zIndex: "100" }} alt="" />
          </a>
        </div>

        <a target="_blank" href="https://instagram.com/smile" rel="noopener noreferrer" ref={this._followLink}>
          <button className="button is-secondary">
            FOLLOW ALONG & TAG US
          </button>
        </a>

      </div>
    );
  }

}

export default InstaFeed;
