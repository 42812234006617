import React, { Component } from "react";
import update from "immutability-helper";
import api from "../../api";
import _isEqual from "lodash.isequal";
import _merge from "lodash.merge";
import TextField from "../form/textField";
import { CountryDropdown, RegionDropdown } from "react-country-region-selector";

const defaultCountry = "US";

const emptyShipping = () => ({
  first_name: "",
  last_name: "",
  company: "",
  address_1: "",
  address_2: "",
  city: "",
  postcode: "",
  country: defaultCountry,
  state: ""
});

export default class UpdateShippingInfo extends Component {

  constructor (props) {
    super(props);

    const shipping = _merge(emptyShipping(), this.props.shipping || {});
    if (!shipping.country) {
      shipping.country = defaultCountry;
    }

    this.state = {
      showAddress2: false,
      saving: false,
      shipping
    };
  }

  init = () => {
    const shipping = _merge(emptyShipping(), this.props.shipping || {});
    if (!shipping.country) {
      shipping.country = defaultCountry;
    }
    this.setState({ shipping });
  };

  handleChange = e => {
    const shipping = update(this.state.shipping, { [e.target.name]: { $set: e.target.value } });
    this.setState({ shipping });
  };

  selectCountry = val => {
    const shipping = update(this.state.shipping, { country: { $set: val } });
    this.setState({ shipping });
  };

  selectRegion = val => {
    const shipping = update(this.state.shipping, { state: { $set: val } });
    this.setState({ shipping });
  };

  handleSubmit = async e => {
    e.preventDefault();

    const changed = !_isEqual(this.props.shipping, this.state.shipping);
    if (changed) {
      try {
        this.setState({ saving: true });
        const newShipping = await api.account.updateShipping(this.state.shipping);
        this.setState({ saving: false });
        this.props.onSuccess && this.props.onSuccess(newShipping);
      } catch (e) {
        this.setState({ saving: false });
        console.error(e);
        alert("Update shipping failed!\nCheck browser developer console for more details");
      }
    } else {
      this.props.onSuccess && this.props.onSuccess();
    }
  };

  toggleShowAddress2 = () => {
    this.setState({ showAddress2: !this.state.showAddress2 });
  };

  render () {
    return (
      <div className={`container spacing${this.props.className ? ` ${this.props.className}` : ""}`}>

        <form onSubmit={this.handleSubmit} className="form">

          <div className="field is-horizontal">
            <div className="field-body">
              <TextField label="First Name:" name="first_name" value={this.state.shipping.first_name} onChange={this.handleChange} />
              <TextField label="Last Name:" name="last_name" value={this.state.shipping.last_name} onChange={this.handleChange} />
            </div>
          </div>

          <TextField label="Shipping Address:" name="address_1" value={this.state.shipping.address_1} onChange={this.handleChange} />

          {this.state.showAddress2 || this.state.shipping.address_2 ?
            <TextField label="Shipping Address 2:" name="address_2" value={this.state.shipping.address_2} onChange={this.handleChange} />
            :
            <button type="button" onClick={this.toggleShowAddress2} className="select-button is-primary mb-3">+ ADD ADDITIONAL INFO</button>
          }

          <div className="field is-horizontal">
            <div className="field-body">
              <div className={`field${this.state.shipping.country ? " has-value" : ""}`}>
                <div className="select is-fullwidth">
                  <CountryDropdown
                    required
                    name="country"
                    valueType="short"
                    value={this.state.shipping.country}
                    onChange={this.selectCountry} />
                </div>
              </div>
              <div className={`field${this.state.shipping.state ? " has-value" : ""}`}>
                <div className="select is-fullwidth">
                  <RegionDropdown
                    required
                    name="state"
                    valueType="short"
                    country={this.state.shipping.country}
                    countryValueType="short"
                    value={this.state.shipping.state}
                    onChange={this.selectRegion} />
                </div>
              </div>
            </div>
          </div>

          <div className="field is-horizontal">
            <div className="field-body">
              <TextField label="City:" name="city" value={this.state.shipping.city} onChange={this.handleChange} />
              <TextField label="Zip Code:" name="postcode" value={this.state.shipping.postcode} onChange={this.handleChange} />
            </div>
          </div>

          <br className="is-hidden-mobile" />
          <button className={`button is-primary is-fullwidth${this.props.buttonClass ? ` ${this.props.buttonClass}` : ""}${this.state.saving ? " is-loading" : ""}`} type="submit">
            {this.props.buttonText || "UPDATE"}
          </button>

        </form>
      </div>
    );
  }

}
