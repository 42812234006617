import React from "react";

export default function accountLoading () {
  return (
    <section className="hero is-medium has-absolute-navbar">
      <div className="hero-body">
        <div className="container">
          <h4 className="has-text-centered"><i className="fas fa-spinner fa-spin has-text-grey-light" /> Account Info Loading...</h4>
        </div>
      </div>
    </section>
  );
}
