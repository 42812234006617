import React, { Component } from "react";
import Modal from "react-modal";

export default class ConfirmModal extends Component {

  constructor (props) {
    super(props);

    this.state = {
      isOpen: false,
      title: "Confirm action",
      text: "Are you sure?",
      callback: () => {}
    };
  }

  closeModal = () => {
    this.setState({ isOpen: false });
  };

  ask = ({ title = "Confirm action", text = "Are you sure?", callback = () => {} }) => {
    this.setState({ title, text, callback }, () => {
      this.setState({ isOpen: true });
    });
  };

  onOkClick = () => {
    this.setState({ isOpen: false });
    this.state.callback && this.state.callback();
  };

  render () {
    return (
      <Modal isOpen={this.state.isOpen} className="smile-modal smile-modal-confirm" overlayClassName="smile-modal--overlay">

        <h2>{this.state.title}</h2>
        <p>{this.state.text}</p>
        <br />
        <div className="field is-grouped">
          <p className="control">
            <button type="button" className="button is-primary" onClick={this.onOkClick}>YES</button>
          </p>
          <p className="control">
            <button type="button" className="button is-primary is-outlined" onClick={this.closeModal}>CANCEL</button>
          </p>
        </div>


        <button type="button" className="smile-modal--close" onClick={this.closeModal} aria-label="close" />
      </Modal>
    );
  }
}
