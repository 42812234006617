import React from "react";

export default function accountInfo (
  {
    customer,
    onEditClick = () => {
      console.log("on edit click");
    }
  }
) {
  return (
    <div className="box has-yellow-background has-text-white" style={{ height: "100%" }}>
      <h4 className="has-text-weight-bold">Account Info</h4>
      <br />
      {customer.first_name}&nbsp;{customer.last_name}<br />
      {customer.email}<br />
      <br />
      <p>Change Password</p>
      <br />
      <button type="button" className="button select-button has-text-danger" onClick={onEditClick}>EDIT</button>
    </div>
  );
}
