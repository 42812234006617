import React from "react";

export default function accountBillingInfo (
  {
    card,
    onEditClick = () => {
      console.log("on edit click");
    }
  }
) {
  return (
    <div className="box has-yellow-background has-text-white" style={{ height: "100%" }}>
      <h4 className="has-text-weight-bold">Billing Info</h4>
      <br />
      {card.cardholder_name}<br />
      {card.card_brand}<br />
      •••• •••• •••• {card.last_4}<br />
      EXP: {card.exp_month}/{card.exp_year}<br />
      <br />
      <button type="button" className="button select-button has-text-danger" onClick={onEditClick}>EDIT</button>
    </div>
  );
}
