import React from "react";
import { formatAmount } from "../../../lib/helpers";

export default function OrderTotal ({ total = 0 }) {
  return (
    <div className="order-total">
      <div className="columns">
        <div className="column is-offset-7-tablet is-5-tablet">
          <div className="columns is-mobile">
            <div className="column">
              <p className="has-text-weight-bold is-size-5">Total paid</p>
            </div>
            <div className="column">
              <p className="has-text-weight-bold is-size-5 has-text-right">${formatAmount(total)}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
