import React from "react";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import PropTypes from "prop-types";

export default function SubscriptionTitle (
  {
    subscription,
    onEditClick = () => console.log("on edit click"),
    onCancelClick = () => console.log("on cancel click")
  }
) {
  
  return (
    <div className="columns subscription-title is-mobile is-vcentered is-multiline">
      <div className="column is-narrow">
        <h4 className="has-text-yellow">Subscription #{subscription.number}</h4>
      </div>
      <div className="column is-narrow">
        <p><strong className={`subscription-status status-${subscription.status}`}>{subscription.status}</strong></p>
      </div>
      <div className={`column is-12-mobile${!subscription.next_payment_date ? ' is-hidden-mobile' : ''}`}>
        {subscription.next_payment_date &&
        <p>Next charge on {format(parseISO(subscription.next_payment_date), "MM/dd/yyyy")}</p>}
      </div>
      <div className="column is-12-mobile is-5-tablet">
        <div className="columns is-mobile">
          <div className="column is-narrow">
          </div>
          <div className="column has-text-right">
            {subscription.canceling ?
              <button type="button" className="button is-uppercase is-transparent has-text-danger is-paddingless" disabled>
                CANCELING...
              </button>
              :
              <button type="button" className="button is-uppercase is-transparent has-text-danger is-paddingless" onClick={onCancelClick}>
                <span className="icon"><i className="fas fa-times" /></span>
                <span>CANCEL SUBSCRIPTION</span>
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  );
}

SubscriptionTitle.propTypes = {
  subscription: PropTypes.object.isRequired,
  onEditClick: PropTypes.func,
  onCancelClick: PropTypes.func
};
