import React from "react";
import Highlightable from "../../highlightable";
import Subscription from "./subscription";
import PropTypes from "prop-types";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import "./subscriptions.scss";

const noop = () => {};

export default function Subscriptions (
  {
    products = [],
    subscriptions = [],
    handleEditSubscription = noop,
    handleCancelSubscription = noop,
    handleUpdateSubscription
  }
) {
  return (
    <section className="section">
      <div className="container">
        <h3 className="has-text-weight-bold" style={{ marginBottom: "0.8rem" }}>
          <Highlightable>Your Monthly Subscription</Highlightable>
        </h3>
        <TransitionGroup>
          {subscriptions.map(subscription =>
            <CSSTransition
              key={subscription.id}
              timeout={500}
              classNames="subscription" // css subscription class prefix see ./subscriptions.scss
            >
              <Subscription
                key={subscription.id}
                subscription={subscription}
                products={products}
                onEditClick={handleEditSubscription}
                onCancelClick={() => handleCancelSubscription(subscription)}
                handleUpdateSubscription={handleUpdateSubscription}
              />
            </CSSTransition>)}
        </TransitionGroup>
      </div>
    </section>
  );
}

Subscriptions.propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  subscriptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleEditSubscription: PropTypes.func,
  handleCancelSubscription: PropTypes.func
};
