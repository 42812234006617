import React from "react";
import auth from "../../lib/auth";
import { navigate } from "gatsby";

export default function accountHero ({ customer }) {

  const handleLogout = e => {
    e.preventDefault();
    auth.logout();
    navigate(`/`);
  };

  return (
    <section className="hero is-medium has-absolute-navbar">
      <div className="hero-body" style={{ paddingBottom: "0px" }}>
        <div className="container">
          <div className="columns">
            <div className="column">
              <h1>Welcome back, {customer.first_name}</h1>
            </div>
            <div className="column is-narrow">
              <button type="button" onClick={handleLogout} className="button is-primary is-outlined">Logout</button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
