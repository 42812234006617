import React from "react";
import _get from "lodash.get";
import QuantityButton from "../../form/quantityButton";
import { renderTags, renderProductNamewithDosage } from "../../../utilities/product";
import { formatAmount } from "../../../lib/helpers";

export default function SubscriptionRow ({ item, product, handleAmountToggle, quantities, handleConfirmChange, confirming }) {
  const imageSrc = _get(product, "images[0].src");
  const imageAlt = _get(product, "images[0].alt") || product.name || null;
  const quantity= quantities.find( q => q.id === item.id);
  const diff = quantity.quantity === item.quantity ? false: true;
  
  return (
    <div className="columns product-row order-row is-vcentered is-variable is-mobile is-multiline">
      <div className="column is-narrow">
        <figure className="image product-row-image is-square is-100x100">
          {imageSrc && <img src={imageSrc} alt={imageAlt} />}
        </figure>
      </div>
      <div className="column">
        <p className="is-size-4 is-inline is-underlined">{renderProductNamewithDosage(product)}</p>
        <p>{renderTags(product)}</p>
      </div>
      <div className="column is-12-mobile is-5-tablet">
        <div className="columns is-vcentered is-mobile">
          <div className="column is-9-mobile is-8-desktop">
            {diff?
            <p>Current Subscription Quantity: {item.quantity}</p>
            :
            <p>Amount: {item.quantity}</p>
            }
            <QuantityButton quantity={quantity.quantity} handleAmountToggle={(amount) => handleAmountToggle(amount, item.id)} />
            {diff?
            confirming ?
            <button type="button" className="button is-secondary quantity-confirm-button" disabled>
              CONFIRMING
            </button>
            :
            <button type="button" className="button is-secondary quantity-confirm-button" onClick={handleConfirmChange}>
              CONFIRM
            </button>
            :
            false
            }
          </div>
          <div className="column is-3-mobile is-4-desktop">
            <p className="is-size-6 has-text-right">${formatAmount(parseFloat(item.price))} / month</p>
          </div>
        </div>
      </div>
    </div>
  );
}
