import React, { Component } from "react";
import TextField from "../form/textField.js";
import PropTypes from "prop-types";
import _get from "lodash.get";

export default class EditAccount extends Component {
  constructor (props) {
    super(props);
    this.state = {
      first_name: _get(props.user, "first_name", ""),
      last_name: _get(props.user, "last_name", ""),
      email: _get(props.user, "email", ""),
      submitting: false
    };
  }

  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleSubmit = e => {
    e.preventDefault();
    console.log("edit account submit");
    // todo api call for update self account
  };

  render () {
    return (
      <div className={`container spacing${this.props.className ? ` ${this.props.className}` : ""}`}>
        <div>
          <h2>Account Info</h2>
          <p>Update your information to access your orders and history more seamlessly.</p>
        </div>
        <br />
        <form onSubmit={this.handleSubmit} className="form">

          <TextField key="first_name" label="First Name" name="first_name" type="text" onChange={this.handleChange} value={this.state.first_name} />
          <TextField key="last_name" label="Last Name" name="last_name" type="text" onChange={this.handleChange} value={this.state.last_name} />
          <TextField key="email" label="E-mail" name="email" type="email" onChange={this.handleChange} value={this.state.email} />

          <br className="is-hidden-mobile" />
          <button className={`button is-primary is-uppercase is-fullwidth${this.state.submitting ? " is-loading" : ""}`} type="submit">
            UPDATE
          </button>
        </form>
      </div>
    );
  }

}

EditAccount.propTypes = {
  user: PropTypes.object.isRequired
};
