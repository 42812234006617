import React from "react";
import _get from "lodash.get";
import OrderGroup from "./order-group";
import OrderTotal from "./order-total";

export default function OrderContent ({ order, products = [] }) {
  const findProduct = product_id => products.find(p => p.wordpress_id === product_id);

  const orderItems = order.line_items.map(item => {
    const product = findProduct(item.product_id);
    return Object.assign({ product }, item);
  });


  const subscriptionOrderItems = orderItems.filter(orderItem => _get(orderItem, "product.type") === "subscription");
  const simpleOrderItems = orderItems.filter(orderItem => _get(orderItem, "product.type") === "simple");

  return (
    <div className="has-text-weight-normal order-content">
      {/* Monthly Subscriptions */}
      {subscriptionOrderItems.length > 0 &&
      <OrderGroup title="Monthly Subscription" type="subscription" orderItems={subscriptionOrderItems} />}

      {/* One-Time Purchase */}
      {simpleOrderItems.length > 0 &&
      <OrderGroup title="One-Time Purchase" type="simple" orderItems={simpleOrderItems} showFooter={false} />
      }

      <OrderTotal total={parseFloat(order.total)} />
    </div>
  );
}
